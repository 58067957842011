import React, { useEffect, useState, useMemo } from 'react';
import findSubApp from '../Everything/findSubApp';
import { Redirect } from 'react-router-dom';
import { getSubAppComponent } from '../Everything/useSubApps';
import ActionsContext from '../Everything/helper/ActionsContext/ActionsContext';
import './SubApp.scss';

export default function SubApp({
  match: {
    params: { appKey: key }
  }
}) {
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [subApp, setSubApp] = useState(null);
  const [SubAppComponent, setSubAppComponent] = useState(null);
  const actionContextValue = useMemo(
    () => ({
      close() {
        setRedirectToHome(true);
      },
      registerCommandListener(commandListener) {},
      unregisterCommandListener(commandListener) {}
    }),
    []
  );

  useEffect(() => {
    const subApp = findSubApp(key);
    setSubApp(subApp);
    if (!subApp) {
      setRedirectToHome(true);
    } else {
      const { key } = subApp;
      getSubAppComponent(key).then(SubAppComponent =>
        setSubAppComponent(() => SubAppComponent)
      );
    }
  }, [key]);

  if (redirectToHome) return <Redirect to="/"></Redirect>;
  if (!subApp || !SubAppComponent) return null;

  const { key: _, ...props } = subApp;
  const { appStyle, ...componentProps } = props;

  return (
    <div className="SubApp" key={key} style={appStyle}>
      <ActionsContext.Provider value={actionContextValue}>
        <SubAppComponent key={key} {...componentProps} />
      </ActionsContext.Provider>
    </div>
  );
}
