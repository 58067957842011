import React, { useState, useRef, useCallback } from 'react';

export default function useHiddenInput({ value, onSubmit, onUpdate }) {
  const hiddenInputRef = useRef(null);
  const focus = useCallback(
    () => hiddenInputRef.current.focus({ preventScroll: true }),
    []
  );
  const [focused, setFocused] = useState(false);
  const hiddenInput = (
    <input
      ref={hiddenInputRef}
      style={{ position: 'absolute', top: -1337 }}
      type="text"
      onChange={e => {
        const {
          nativeEvent: {
            target: { value }
          }
        } = e;
        onUpdate(value);
      }}
      onKeyPress={({ charCode }) => {
        if (charCode === 13) onSubmit();
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      value={value}
    />
  );
  return { hiddenInput, focus, focused };
}
