import create from 'zustand';

const [useSubApps] = create(set => ({
  subApps: [],
  async addSubApp(key, props = {}, commandListeners = []) {
    const Component = await getSubAppComponent(key);

    const actions = {
      close() {
        set(({ subApps }) => ({
          subApps: subApps.filter(prevSubApp => prevSubApp.key !== key)
        }));
      },
      registerCommandListener(commandListener) {
        changeSubApp(set, key, ({ commandListeners }) => ({
          commandListeners: [...commandListeners, commandListener]
        }));
      },
      unregisterCommandListener(commandListener) {
        changeSubApp(set, key, ({ commandListeners }) => ({
          commandListeners: commandListeners.filter(
            cl => cl !== commandListener
          )
        }));
      }
    };
    const subApp = {
      Component,
      props,
      key,
      actions,
      commandListeners
    };
    set(({ subApps }) => ({
      subApps: [subApp, ...subApps.filter(subApp => subApp.key !== key)]
    }));
  }
}));

function changeSubApp(set, key, getNewProperties) {
  set(({ subApps }) => ({
    subApps: subApps.map(subApp =>
      subApp.key === key
        ? {
            ...subApp,
            ...getNewProperties(subApp)
          }
        : subApp
    )
  }));
}

export async function getSubAppComponent(key) {
  return (await import(`./${key}/${key}`)).default;
}

export default useSubApps;
