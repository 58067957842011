export default function Time({ time }) {
  if (!time) return null;
  const seconds = Math.floor(time / 1000);
  if (seconds < 1) return `${time}ms`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 1)
    return `${seconds}s ${String(time % 1000).padStart(3, '0')}ms`;
  const hours = Math.floor(minutes / 60);
  if (hours < 1)
    return `${minutes}min ${String(seconds % 60).padStart(2, '0')}s`;
  return `${hours}h ${String(minutes % 60).padStart(2, '0')}min`;
}
