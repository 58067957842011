import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SubApp from './App/SubApp';

ReactDOM.render(<RoutedApp />, document.getElementById('root'));

function RoutedApp() {
  return (
    <Router>
      <Route path="/" exact component={App}></Route>
      <Route path="/app/:appKey" component={SubApp}></Route>
    </Router>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
