const lowerTextEqualCases = new Map([
  ['car', { appStyle: { '--max-width': '700px' } }],
  ['xkcd', { appStyle: { '--max-width': '780px' } }],
  [
    'correcthorsebatterystaple',
    () => ({ ...getAppProps('xkcd'), comicNr: 936 })
  ],
  ['about'],
  ['tools'],
  ['numbers']
]);

function getAppProps(key) {
  const furtherOptions = lowerTextEqualCases.get(key);
  return {
    key,
    ...(typeof furtherOptions === 'function'
      ? furtherOptions()
      : furtherOptions)
  };
}

export default function findSubApp(lowerText) {
  if (lowerTextEqualCases.has(lowerText)) {
    return getAppProps(lowerText);
  }
}
