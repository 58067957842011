import React, { useState, useEffect } from 'react';
import Box from './Box';

export default function RunningBox({ lastTime, ...rest }) {
  const getTimeUsed = () => Date.now() - lastTime;
  const [timeUsed, setTimeUsed] = useState(getTimeUsed());
  useEffect(() => {
    let running = true;
    (function updateTime() {
      if (!running) return;
      setTimeUsed(getTimeUsed());
      requestAnimationFrame(updateTime);
    })();
    return () => (running = false);
  });
  return <Box {...rest} timeUsed={timeUsed} />;
}
