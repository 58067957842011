import React, { useState, useEffect } from 'react';
import Box from './Box';
import RunningBox from './RunningBox';
import LargeNumberBox from './LargeNumberBox';
import useHiddenInput from './useHiddenInput';
import './Boxes.scss';

export default function Boxes({ onSubmit }) {
  const minLength = 6;
  const [currentCharStart, setCurrentCharStart] = useState(Date.now());
  const [chars, setChars] = useState([]);
  const charsWithTimeUsed = chars.map(char => ({
    ...char,
    timeUsed: char.timeEnd - char.timeStart
  }));

  function updateChars(value) {
    const time = Date.now();
    const rawChars = [...value];
    let correctChars = 0;
    for (let i = 0; i <= chars.length; i++) {
      if (i === chars.length) {
        correctChars = chars.length;
        break;
      }
      if (rawChars[i] !== chars[i].value) {
        correctChars = i;
        break;
      }
    }
    setChars([
      ...chars.filter((_, i) => i < correctChars),
      ...[...value.slice(correctChars)].map((value, i) => ({
        value,
        timeStart: i === 0 ? currentCharStart : time,
        timeEnd: time
      }))
    ]);
    setCurrentCharStart(time);
  }
  const { hiddenInput, focus, focused } = useHiddenInput({
    value: chars.map(char => char.value).join(''),
    onUpdate(value) {
      updateChars(value);
    },
    async onSubmit() {
      if (await onSubmit(chars)) {
        setChars([]);
      }
    }
  });
  useEffect(() => focus(), [focus]);

  return (
    <>
      {hiddenInput}
      <div className="Boxes" onClick={focus}>
        {charsWithTimeUsed.map(({ value, timeUsed }, i) => (
          <Box key={i} number={i} value={value} timeUsed={timeUsed} />
        ))}
        {focused ? (
          <RunningBox
            lastTime={currentCharStart}
            active={focused}
            number={chars.length}
          />
        ) : (
          chars.length < minLength && <LargeNumberBox number={chars.length} />
        )}
        {chars.length < minLength &&
          new Array(minLength - chars.length - 1)
            .fill(0)
            .map((_, i) => (
              <LargeNumberBox key={i} number={i + chars.length + 1} />
            ))}
      </div>
    </>
  );
}
