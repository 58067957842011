import React from 'react';
import Time from './Time';
import './Box.scss';

export default function Box({ active, number, value, timeUsed }) {
  return (
    <div className={`Box${active ? ' active' : ''}`}>
      <div className="value">{value && value.toUpperCase()}</div>
      <div className="number">#{number}</div>
      {value && (
        <div className="codePoint">
          {value.toUpperCase() !== value && (
            <div className="upperCase" title="Uppercase">
              ⇧
            </div>
          )}
          <a
            href={`http://shapecatcher.com/unicode/info/${value
              .codePointAt(0)
              .toString(10)}`}
          >
            {`0x${value.codePointAt(0).toString(16)}`}
          </a>
        </div>
      )}
      <div className="timing">
        <Time time={timeUsed} />
      </div>
    </div>
  );
}
