import React from 'react';
import Boxes from '../Boxes/Boxes';
import useSubApps from '../Everything/useSubApps';
import findSubApp from '../Everything/findSubApp';
import ActionsContext from '../Everything/helper/ActionsContext/ActionsContext';
import './App.scss';

function App() {
  const { subApps, addSubApp } = useSubApps();

  return (
    <div className="App">
      <Boxes
        onSubmit={async chars => {
          const lowerText = chars
            .map(({ value }) => value)
            .join('')
            .toLowerCase();
          const subApp = findSubApp(lowerText);
          if (subApp) {
            const { key: subAppKey, ...props } = subApp;
            await addSubApp(subAppKey, props);
            return true;
          }
          if (subApps[0] && subApps[0].commandListeners.length) {
            subApps[0].commandListeners.forEach(cl => cl(lowerText));
            return true;
          }
        }}
      />
      <div className="subApps">
        {subApps.map(
          ({
            Component,
            props: { appStyle, ...componentProps },
            key,
            actions
          }) => (
            <div className="subApp" key={key} style={appStyle}>
              {console.log(componentProps)}
              <ActionsContext.Provider value={actions}>
                <Component key={key} {...componentProps} />
              </ActionsContext.Provider>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default App;
