import React from 'react';

export default function LargeNumberBox({ number }) {
  return (
    <div className="Box">
      <div className="largeNumber">
        <div className="inner">{number}</div>
      </div>
    </div>
  );
}
